import './css/Navbar.css'
import { useAuth } from '../context/AuthContext'
import { useState } from 'react'

function Navbar() {
    const {logOut} = useAuth()
    const [isActiveUser, setIsActiveUser] = useState(false)

    const cerrarSesion = () => {
        logOut()
    }

    const clickIconoUser = () => {
        setIsActiveUser(!isActiveUser)
    }

    document.addEventListener('click', function(event) {
        if(event.target.id != 'navbar-icono' && event.target.id != 'navbar-icono-user' && event.target.id != 'navbar-expand' && event.target.id != 'navbar-expand-cerrar-sesion'){
            setIsActiveUser(false)
        }
    });

    return (
        <nav>
            <div className='container'>
                <img className='navbar-logo' src='logo.png' alt='logo' />
                <div className='navbar-controls'>
                    <div id='navbar-icono' onClick={clickIconoUser} className={isActiveUser ? 'navbar-icono active' : 'navbar-icono'}>
                        <img id='navbar-icono-user' className={isActiveUser ? 'navbar-icono-user active' : 'navbar-icono-user'} src='icono-user.png' alt='icono user' />
                    </div>
                    <div id='navbar-expand' className={isActiveUser ? 'navbar-expand active' : 'navbar-expand'}>
                        <div id='navbar-expand-cerrar-sesion' onClick={cerrarSesion}>Cerrar sesión</div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar