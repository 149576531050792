import {useNavigate} from 'react-router-dom'
import { useAuth } from '../context/AuthContext.js'
import { useEffect } from 'react'

const NotFoundPage = () => {
    const { isAuthenticated } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if(isAuthenticated) navigate('/')
    }, [isAuthenticated])

    return (
        <div>NotFoundPage</div>
    )
}

export default NotFoundPage