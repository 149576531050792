import './css/Client.css'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Spinner } from 'reactstrap';
import { useClients } from '../context/ClientContext.js'
import Modal from './Modal.js'
import { format } from 'date-fns'

function Client({client, index}) {
    const {deleteClient, deleteErrors, setDeleteErrors, loadingDeleteClient, updateErrors, setUpdateErrors, loadingUpdateClient, updateUsernameClient,updateEmailClient, updatePasswordClient, updateSheetClient, updatePluginClient, updateActiveClient, updateExpirationClient} = useClients()
    const {register, handleSubmit, formState: {errors}, reset} = useForm()
    const {register: register1, handleSubmit: handleSubmit1, formState: {errors: errors1}, reset: reset1} = useForm()
    const {register: register2, handleSubmit: handleSubmit2, formState: {errors: errors2}, reset: reset2} = useForm()
    const {register: register3, handleSubmit: handleSubmit3, formState: {errors: errors3}, reset: reset3} = useForm()
    const {register: register4, handleSubmit: handleSubmit4, formState: {errors: errors4}, reset: reset4} = useForm()
    const {register: register5, handleSubmit: handleSubmit5, formState: {errors: errors5}, reset: reset5} = useForm()
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const [showEditUsername, setShowEditUsername] = useState(false)
    const [disabledButtonEditUsername, setDisabledButtonEditUsername] = useState(true)
    const [valueEditUsername, setValueEditUsername] = useState(client.username)

    const [showEditEmail, setShowEditEmail] = useState(false)
    const [disabledButtonEditEmail, setDisabledButtonEditEmail] = useState(true)
    const [valueEditEmail, setValueEditEmail] = useState(client.email)

    const [showEditPassword, setShowEditPassword] = useState(false)

    const [showEditSheet, setShowEditSheet] = useState(false)
    const [disabledButtonEditSheet, setDisabledButtonEditSheet] = useState(true)
    const [valueEditSheet, setValueEditSheet] = useState(client.sheet)

    const [showEditPlugin, setShowEditPlugin] = useState(false)
    const [disabledButtonEditPlugin, setDisabledButtonEditPlugin] = useState(true)
    const [valueEditPlugin, setValueEditPlugin] = useState(client.plugin)

    const [showEditActive, setShowEditActive] = useState(false)

    const [showEditExpiration, setShowEditExpiration] = useState(false)

    // ------------------------------------------------------------------------------------------------

    const abrirModalEditar = () => setShowEdit(true)

    const abrirModalBorrar = () => setShowDelete(true)

    const cerrarModalEditar = () => setShowEdit(false)

    const cerrarModalBorrar = () => {
        setShowDelete(false)
        setDeleteErrors([])
    }

    const borrarCliente = async () => {
        if(await deleteClient(client.username)) {
            cerrarModalBorrar()
        }
    }

    // USERNAME----------------------------------------------------------------------------------------

    const resetForm4 = () => {
        reset4()
        setUpdateErrors([])
    }

    const abrirModalEditarUsername = () => {
        cerrarModalEditar()
        setValueEditUsername(client.username)
        setShowEditUsername(true)
    }

    const cerrarModalEditarUsername = () => {
        setShowEditUsername(false)
        resetForm4()
        setDisabledButtonEditUsername(true)
    }

    const editarUsernameCliente = handleSubmit4(async (values) => {
        values.username = valueEditUsername
        if(await updateUsernameClient(client.username, values)) {
            cerrarModalEditarUsername()
        }
    })

    const verificarBotonEditUsername = (e) => {
        if(e.target.value === client.username) {
            setDisabledButtonEditUsername(true)
        } else {
            setDisabledButtonEditUsername(false)
        }
        setValueEditUsername(e.target.value)
    }

    // EMAIL-------------------------------------------------------------------------------------------

    const resetForm = () => {
        reset()
        setUpdateErrors([])
    }

    const abrirModalEditarEmail = () => {
        cerrarModalEditar()
        setValueEditEmail(client.email)
        setShowEditEmail(true)
    }

    const cerrarModalEditarEmail = () => {
        setShowEditEmail(false)
        resetForm()
        setDisabledButtonEditEmail(true)
    }

    const editarEmailCliente = handleSubmit(async (values) => {
        values.email = valueEditEmail
        if(await updateEmailClient(client.username, values)) {
            cerrarModalEditarEmail()
        }
    })

    const verificarBotonEditEmail = (e) => {
        if(e.target.value === client.email) {
            setDisabledButtonEditEmail(true)
        } else {
            setDisabledButtonEditEmail(false)
        }
        setValueEditEmail(e.target.value)
    }

    // PASSWORD----------------------------------------------------------------------------------------

    const resetForm1 = () => {
        reset1()
        setUpdateErrors([])
    }

    const abrirModalEditarPassword = () => {
        cerrarModalEditar()
        setShowEditPassword(true)
    }

    const cerrarModalEditarPassword = () => {
        setShowEditPassword(false)
        resetForm1()
    }

    const editarPasswordCliente = handleSubmit1(async (values) => {
        if(await updatePasswordClient(client.username, values)) {
            cerrarModalEditarPassword()
        }
    })

    // SHEET-------------------------------------------------------------------------------------------

    const resetForm2 = () => {
        reset2()
        setUpdateErrors([])
    }

    const abrirModalEditarSheet = () => {
        cerrarModalEditar()
        setValueEditSheet(client.sheet)
        setShowEditSheet(true)
    }

    const cerrarModalEditarSheet = () => {
        setShowEditSheet(false)
        resetForm2()
        setDisabledButtonEditSheet(true)
    }

    const editarSheetCliente = handleSubmit2(async (values) => {
        values.sheet = valueEditSheet
        if(await updateSheetClient(client.username, values)) {
            cerrarModalEditarSheet()
        }
    })

    const verificarBotonEditSheet = (e) => {
        if(e.target.value === client.sheet) {
            setDisabledButtonEditSheet(true)
        } else {
            setDisabledButtonEditSheet(false)
        }
        setValueEditSheet(e.target.value)
    }

    // PLUGIN------------------------------------------------------------------------------------------

    const resetForm3 = () => {
        reset3()
        setUpdateErrors([])
    }

    const abrirModalEditarPlugin = () => {
        cerrarModalEditar()
        setValueEditPlugin(client.plugin)
        setShowEditPlugin(true)
    }

    const cerrarModalEditarPlugin = () => {
        setShowEditPlugin(false)
        resetForm3()
        setDisabledButtonEditPlugin(true)
    }

    const editarPluginCliente = handleSubmit3(async (values) => {
        values.plugin = valueEditPlugin
        if(await updatePluginClient(client.username, values)) {
            cerrarModalEditarPlugin()
        }
    })

    const verificarBotonEditPlugin = (e) => {
        if(e.target.value === client.plugin) {
            setDisabledButtonEditPlugin(true)
        } else {
            setDisabledButtonEditPlugin(false)
        }
        setValueEditPlugin(e.target.value)
    }

    // ACTIVE------------------------------------------------------------------------------------------

    const abrirModalEditarActive = () => {
        cerrarModalEditar()
        setShowEditActive(true)
    }

    const cerrarModalEditarActive = () => {
        setShowEditActive(false)
        setUpdateErrors([])
    }

    const editarActiveCliente = async () => {
        if(await updateActiveClient(client.username)) {
            cerrarModalEditarActive()
        }
    }

    // EXPIRATION--------------------------------------------------------------------------------------

    const resetForm5 = () => {
        reset5()
        setUpdateErrors([])
    }

    const abrirModalEditarExpiration = () => {
        cerrarModalEditar()
        setShowEditExpiration(true)
    }

    const cerrarModalEditarExpiration = () => {
        setShowEditExpiration(false)
        resetForm5()
    }

    const borrarExpirationCliente = handleSubmit5(async (values) => {
        values = {expiration: 0}
        if(await updateExpirationClient(client.username, values)) {
            cerrarModalEditarExpiration()
        }
    })

    const editarExpirationCliente = handleSubmit5(async (values) => {
        values.expiration = Number(values.expiration)
        if(await updateExpirationClient(client.username, values)) {
            cerrarModalEditarExpiration()
        }
    })

    return (
        <div className={'col-12 ' + (index % 2 == 0 ? 'box-clients-table-users' : 'box-clients-table-users-gray')}>
            <div className='col-3 text-center text-break ps-1 pe-1 fw-bold'>{client.username}</div>
            <div className='col-3 text-center text-break ps-1 pe-1'>{client.email}</div>
            <div className='col-2 text-center ps-1 pe-1'>
                {client.active
                ? <span className='badge badge-success'>
                    Si
                </span>
                : <span className='badge badge-danger'>
                    No
                </span>
                }
            </div>
            <div className='col-4 text-center ps-1 pe-1 box-clients-table-users-buttons'>
                <div className='box-clients-table-users-button-edit'>
                    <button onClick={abrirModalEditar}>Editar</button>
                </div>
                <div className='box-clients-table-users-button-delete'>
                    <button onClick={abrirModalBorrar}>Eliminar</button>
                </div>
            </div>
            <Modal
                show={showEdit}
                setShow={setShowEdit}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar usuario <span>{client.username}</span></div>
                    <div className='col-12 client-modal-edit'>
                        <button className='col-12' onClick={abrirModalEditarUsername}>Editar nombre de usuario</button>
                        <button className='col-12' onClick={abrirModalEditarPassword}>Editar password</button>
                        <button className='col-12' onClick={abrirModalEditarEmail}>Editar email</button>
                        <button className='col-12' onClick={abrirModalEditarSheet}>Editar sheet</button>
                        <button className='col-12' onClick={abrirModalEditarPlugin}>Editar plugin</button>
                        <button className='col-12' onClick={abrirModalEditarActive}>Pasar de {client.active ? 'activo a inactivo' : 'inactivo a activo'}</button>
                        <button className='col-12' onClick={abrirModalEditarExpiration}>{client.expiration === null ? 'Crear expiración' : 'Editar expiración'}</button>
                    </div>
                    <div className='client-modal-buttons'>
                        <button onClick={cerrarModalEditar} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showDelete}
                setShow={cerrarModalBorrar}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title'>Se eliminará el usuario <span>{client.username}</span>, ¿estás seguro?</div>
                    {deleteErrors.length > 0 && <div className='login-page-errors'>
                        {deleteErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {deleteErrors.message && <div className='login-page-errors'>
                        <div>{deleteErrors.message}</div>
                    </div>
                    }
                    {loadingDeleteClient && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button onClick={borrarCliente} className='client-modal-buttons-red'>Eliminar</button>
                        <button onClick={cerrarModalBorrar} className='client-modal-buttons-blue'>Cancelar</button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showEditUsername}
                setShow={cerrarModalEditarUsername}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar usuario de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarUsernameCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Usuario</label>
                            <input
                                className='col-9' type='text' placeholder='Nombre de usuario...'
                                {...register4('username', {required: true})}
                                onChange={verificarBotonEditUsername}
                                value={valueEditUsername}
                            />
                        </div>
                        {errors4.username && <p className='client-modal-inputs-errors'>El nombre de usuario es requerido</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarUsernameCliente} className='client-modal-buttons-blue' disabled={disabledButtonEditUsername}>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarUsername} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showEditPassword}
                setShow={cerrarModalEditarPassword}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar password de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarPasswordCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Pass</label>
                            <input
                                className='col-9' type='text' placeholder='Nueva contraseña...'
                                {...register1('password', {required: true, minLength: 6})}
                            />
                        </div>
                        {errors1.password && errors1.password.type === 'required' && <p className='client-modal-inputs-errors'>La contraseña es requerida</p>}
                        {errors1.password && errors1.password.type === 'minLength' && <p className='client-modal-inputs-errors'>La contraseña debe tener al menos 6 carácteres</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarPasswordCliente} className='client-modal-buttons-blue'>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarPassword} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showEditEmail}
                setShow={cerrarModalEditarEmail}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar email de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarEmailCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Email</label>
                            <input
                                className='col-9' type='email' placeholder='Email...'
                                {...register('email', {required: true})}
                                onChange={verificarBotonEditEmail}
                                value={valueEditEmail}
                            />
                        </div>
                        {errors.email && <p className='client-modal-inputs-errors'>El email es requerido</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarEmailCliente} className='client-modal-buttons-blue' disabled={disabledButtonEditEmail}>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarEmail} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showEditSheet}
                setShow={cerrarModalEditarSheet}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar sheet de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarSheetCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Sheet</label>
                            <input
                                className='col-9' type='text' placeholder='Link sheet...'
                                {...register2('sheet', {required: true})}
                                onChange={verificarBotonEditSheet}
                                value={valueEditSheet}
                            />
                        </div>
                        {errors2.sheet && <p className='client-modal-inputs-errors'>El link del sheet es requerido</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarSheetCliente} className='client-modal-buttons-blue' disabled={disabledButtonEditSheet}>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarSheet} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showEditPlugin}
                setShow={cerrarModalEditarPlugin}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar plugin de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarPluginCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Plugin</label>
                            <input
                                className='col-9' type='text' placeholder='Link plugin...'
                                {...register3('plugin', {required: true})}
                                onChange={verificarBotonEditPlugin}
                                defaultValue={valueEditPlugin}
                            />
                        </div>
                        {errors3.plugin && <p className='client-modal-inputs-errors'>El link del plugin es requerido</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarPluginCliente} className='client-modal-buttons-blue' disabled={disabledButtonEditPlugin}>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarPlugin} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showEditActive}
                setShow={cerrarModalEditarActive}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title'>Se pasará de {client.active ? 'activo a inactivo' : 'inactivo a activo'} al usuario <span>{client.username}</span>, ¿estás seguro?</div>
                    {updateErrors.length > 0 && <div className='login-page-errors'>
                        {updateErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {updateErrors.message && <div className='login-page-errors'>
                        <div>{updateErrors.message}</div>
                    </div>
                    }
                    {loadingUpdateClient && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button onClick={editarActiveCliente} className='client-modal-buttons-blue'>Guardar</button>
                        <button onClick={cerrarModalEditarActive} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showEditExpiration}
                setShow={cerrarModalEditarExpiration}
            >
                {client.expiration !== null
                ? <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar expiración de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={borrarExpirationCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label>Expiración:</label>
                            <div>{format(new Date(client.expiration), 'dd-MM-yyyy HH:mm') + 'hs'}</div>
                        </div>
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={borrarExpirationCliente} className='client-modal-buttons-red'>Borrar</button>
                            <button type='reset' onClick={cerrarModalEditarExpiration} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
                : <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Crear expiración de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarExpirationCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Expiración</label>
                            <input
                                className='col-9' type='number' placeholder='Días restantes para la expiración...'
                                {...register5('expiration', {required: true, min: 1})}
                            />
                        </div>
                        {errors5.expiration && errors5.expiration.type === 'required' && <p className='client-modal-inputs-errors'>Los días de expiración son requeridos</p>}
                        {errors5.expiration && errors5.expiration.type === 'min' && <p className='client-modal-inputs-errors'>El número de días debe ser mayor a 0</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarExpirationCliente} className='client-modal-buttons-blue'>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarExpiration} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
                }
            </Modal>
        </div>
    )
}

export default Client